import { PrimeReactProvider } from 'primereact/api';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { FilterProvider } from './context/FilterContext';
import { ShoppingCartProvider } from './context/ShoppingCartContext';
import SplashScreen from './pages/splash/Splash';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/md-light-indigo/theme.css';
import { BusinessUnitProvider } from './components/BusinessUnit/BusinessUnitProvider';
import ChoiceCustomer from './pages/choiceCustomer/ChoiceCustomer';
import Products from './pages/home/Products';
import { AuthContextProvider } from './context/AuthContext';

const App: React.FC = () => {
  return (
    <div className="App">
      <Router> 
        <AuthContextProvider>
          <ShoppingCartProvider>
            <PrimeReactProvider value={{ unstyled: false }}>
              <FilterProvider>
                <BusinessUnitProvider>
                  <Routes>
                    <Route path="/" element={<SplashScreen />} />
                    <Route path="/products" element={<Products />} />
                    <Route
                      path="/choiceCustomer"
                      element={<ChoiceCustomer />}
                    />
                  </Routes>
                </BusinessUnitProvider>
              </FilterProvider>
            </PrimeReactProvider>
          </ShoppingCartProvider>
        </AuthContextProvider>
      </Router>
    </div>
  );
};

export default App;
