import { saveAs } from 'file-saver';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import DarkMenuBar from '../../components/DarkMenuBar/DarkMenuBar';
import FilterSideBar from '../../components/FilterSideBar/FilterSideBar';
import { ProductCardList } from '../../components/ProductCard/ProductCardList';
import { ProductTableList } from '../../components/ProductRow/ProductTableList';
import { useBusinessUnit } from '../../context/BusinessUnitContext';
import { useFilter } from '../../context/FilterContext';
import PictureProduct from '../../models/PictureProduct';
import Product from '../../models/Product';
import { PlatformService } from '../../services/platform.service';
import { ProductService } from '../../services/product.service';
import { PictureProfileService } from '../../services/profilePicture.service';
import { AuthService } from '../../services/auth.service';
import './Home.css';
import { DelegateService } from '../../services/delegate.service';

export default function Products() {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [products, setProducts] = useState<Product[]>([]);
  const { filters } = useFilter();
  const [pictures, setPictures] = useState<PictureProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [idLanguageUser, setIdLanguageUser] = useState('es');
  const [isDelegate, setIsDelegate] = useState(false);
  const [platforms, setPlatforms] = useState<string[]>([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { businessUnitKey, businessUnitName } = useBusinessUnit();
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const productsCounter = `${products.length} ${t('home.amount_products_label.substring_1')} ${businessUnitKey == null || businessUnitKey == '' ? t('home.amount_products_label.substring_2') : businessUnitName}`;

  const validateEmail = async () => {
    try{
      await AuthService.ValidateEmail(); 
    }
    catch(error){
      console.error('Error al validar el email.', error); 
    }
  }

  useEffect(() => {
    setIsLoading(true);
    validateEmail(); 
    checkIfDelegate();
    getProducts(searchQuery);
    setIsLoading(false);

    
  }, [idLanguageUser, businessUnitKey, filters]);

  function checkIfDelegate() {
    const role = AuthService.GetRole(); 
    if (role === 'Delegate') {
      setIsDelegate(true); 
    }
    else{
      setIsDelegate(false); 
    }
  }

  function getProducts(query: string) {
    console.log(AuthService.GetLanguageID());
    setIdLanguageUser(AuthService.GetLanguageID() ?? 'es');

    ProductService.GetProducts(businessUnitKey ?? '').then(
      (response) => {
        const responseProducts = response.data as Product[];

        const typeFilteredProducts = filters.productType.length
          ? responseProducts.filter((product) =>
              filters.productType.includes(
                product.productType?.toUpperCase() ?? '',
              ),
            )
          : responseProducts;

        const platformFilteredProducts = filters.platform.length
          ? typeFilteredProducts.filter((product) =>
              filters.platform.includes(product.family?.toUpperCase() ?? ''),
            )
          : typeFilteredProducts;

         const languagesFilteredProducts = filters.idLanguage.length
          ? platformFilteredProducts.filter((product) =>
              filters.idLanguage.includes(product.idLanguage  ?? '') || product.idLanguage=='',
            )
          : platformFilteredProducts.filter((product) => product.idLanguage == idLanguageUser || product.idLanguage=='');


        const onDemandFilteredProducts = languagesFilteredProducts.filter(
          (product) => {
            if (filters.isOnDemand === undefined) {
              return product;
            }
            return product.isOnDemand === filters.isOnDemand;
          },
        );

        const endOfStockFilteredProducts = onDemandFilteredProducts.filter(
          (product) => {
            if (filters.endOfStock === undefined) {
              return product;
            }
            else if (filters.endOfStock === true){
              return product.isEnabled === "Agotar"; 
            }
            else{
              return product; 
            }
          },
        );
        
        if (query === '') {
          getPlatforms();
          setProducts(sortProductsByPlatform(endOfStockFilteredProducts));
          return;
        } else {
          const searchFilteredProducts = endOfStockFilteredProducts.filter(
            (product) =>
              product.nameProduct.toLowerCase().includes(query.toLowerCase()) ||
              product.code.toLowerCase().includes(query.toLowerCase()),
          );
          query;

          getPlatforms();
          setProducts(sortProductsByPlatform(searchFilteredProducts));
        }
      },
    );
    PictureProfileService.GetPicturesProduct().then((response) => {
      const responsePictures = response.data as PictureProduct[];
      setPictures(responsePictures);
    });
  }

  function getPlatforms() {
    PlatformService.GetPlatformsOfTheProducts().then((response) => {
      const responseData = response.data as string[];
      setPlatforms(responseData);
    });
  }

  function sortProductsByPlatform(products: Product[]) {
    const platformOrder = platforms.map((p) => p.toUpperCase());
  
    const sortedProducts = products.sort((a, b) => {
      const platformA = a.family?.toUpperCase() ?? '';
      const platformB = b.family?.toUpperCase() ?? '';
  
      const indexA = platformOrder.indexOf(platformA);
      const indexB = platformOrder.indexOf(platformB);
  
      if (indexA !== -1 && indexB !== -1) {
        if (indexA !== indexB) {
          return indexA - indexB;
        }
        const nameA = a.nameProduct?.toUpperCase() ?? '';
        const nameB = b.nameProduct?.toUpperCase() ?? '';
        if (nameA > nameB) return 1;
        if (nameA < nameB) return -1;
        return 0;
      }
  
      if (indexA !== -1) {
        return -1;
      }
  
      if (indexB !== -1) {
        return 1;
      }
  
      if (platformA > platformB) return 1;
      if (platformA < platformB) return -1;
  
      const nameA = a.nameProduct?.toUpperCase() ?? '';
      const nameB = b.nameProduct?.toUpperCase() ?? '';
      if (nameA > nameB) return 1;
      if (nameA < nameB) return -1;
  
      return 0;
    });
  
    return sortedProducts;
  }
  

  function downloadProductsToXLSX() {
    ProductService.GetProductsByLanguage(idLanguageUser).then((response) => {
      const responseData = response.data as Product[];
      const sortedData = sortProductsByPlatform(responseData);
      setAllProducts(sortedData);

      const worksheetData = sortedData.map((product) => ({
        [t('home.excel_document.code').toLocaleUpperCase()]: product.code,
        [t('home.excel_document.description').toLocaleUpperCase()]:
          product.nameProduct.toLocaleLowerCase(),
        [t('home.excel_document.business_unit').toLocaleUpperCase()]:
          product.businessUnit?.toLocaleLowerCase() == 'resto'
            ? 'corporativo'
            : product.businessUnit?.toLocaleLowerCase(),
        [t('home.excel_document.platform').toLocaleUpperCase()]:
          product.family?.toLocaleLowerCase(),
        [t('home.excel_document.subplatform').toLocaleUpperCase()]:
          product.subfamily?.toLocaleLowerCase(),
        [t('home.excel_document.product_type').toLocaleUpperCase()]:
          product.productType?.toLocaleLowerCase(),
        [t('home.excel_document.price').toLocaleUpperCase()]:
          `${product.price?.toFixed(2)} €`.toLocaleLowerCase(),
        [t('home.excel_document.is_on_demand.title').toLocaleUpperCase()]:
          product.isOnDemand
            ? t('home.excel_document.is_on_demand.accept').toLocaleLowerCase()
            : t('home.excel_document.is_on_demand.reject').toLocaleLowerCase(),
      }));
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);

      const range = XLSX.utils.decode_range(worksheet['!ref'] || '');
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = XLSX.utils.encode_cell({ c: C, r: 0 });
        if (!worksheet[cell_address]) continue;
        worksheet[cell_address].s = {
          font: {
            name: 'Arial',
            sz: 12,
            bold: true,
          },
          alignment: {
            vertical: 'center',
            horizontal: 'center',
          },
        };
      }

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });

      const blob = new Blob([excelBuffer], {
        type: 'application/octet-stream',
      });

      const date = new Date();
      const formattedDate = date.toISOString().slice(0, 10);
      const formattedTime = date.toTimeString().slice(0, 8).replace(/:/g, '');

      saveAs(blob, `Merchandising_${formattedDate}_${formattedTime}.xlsx`);
    });
  }

  function handleSearchQuery(query: string) {
    setSearchQuery(query);
    getProducts(query);
  }

  return (
    <>
      {isLoading ? (
        <div className="card flex justify-content-center">
          <ProgressSpinner />
        </div>
      ) : (
        <div style={{ overflowX: 'hidden' }}>
          <DarkMenuBar />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px',
              marginRight: '20px',
            }}
          >
            <div
              className="name-product"
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginRight: '10px',
                marginTop: '12px',
                marginLeft: '1.2%',
                flex: 1,
              }}
            >
              {isDelegate ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <i
                    className="pi pi-arrow-left"
                    style={{ marginTop: '1%', cursor: 'pointer' }}
                    onClick={() => navigate('/choiceCustomer')}
                  ></i>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '10px',
                    }}
                  >
                    <strong>{t('banner_delegate.delegate')}</strong>
                    <span style={{ marginLeft: '5px' }}>
                      {AuthService.GetUserEmail()}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '9px',
                    }}
                  >
                    <strong>{t('banner_delegate.client')}</strong>
                    <span style={{ marginLeft: '10px', width: '110px' }}>
                      {DelegateService.GetDelegateName()}
                    </span>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '5px',
                  }}
                >
                  <strong>{t('banner_delegate.user')}</strong>
                  <span style={{ marginLeft: '15px' }}>
                    {AuthService.GetUserEmail()}
                  </span>
                </div>
              )}
            </div>

            <div
              style={{
                position: 'relative',
                marginRight: '20px',
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <input
                type="text"
                value={searchQuery}
                className="input-search-home"
                onChange={(e) => handleSearchQuery(e.target.value)}
                style={{
                  padding: '10px',
                  paddingRight: '30px',
                  border: '1px solid #ccc',
                  width: '300px',
                  marginTop: '3.2%',
                }}
              />
              <i
                className="pi pi-search"
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  color: '#ccc',
                  marginTop: '1.3%',
                }}
              ></i>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                flex: 1,
              }}
            >
              <div
                className="name-product"
                style={{
                  width: '160px',
                  textAlign: 'right',
                  marginRight: '10px',
                }}
              >
                <strong>{productsCounter}</strong>
              </div>
              {isDelegate && (
                <Button
                  icon="pi pi-download"
                  onClick={() => downloadProductsToXLSX()}
                  className="p-button-outlined"
                  style={{
                    borderColor: viewMode === 'list' ? 'black' : '#C6C6C6',
                    color: viewMode === 'list' ? 'black' : '#C6C6C6',
                    backgroundColor: 'white',
                  }}
                />
              )}
              <Button
                icon="pi pi-table"
                onClick={() => setViewMode('grid')}
                className="p-button-outlined p-mr-2"
                style={{
                  borderColor: viewMode === 'grid' ? 'black' : '#C6C6C6',
                  color: viewMode === 'grid' ? 'black' : '#C6C6C6',
                  backgroundColor: 'white',
                }}
              />
              <Button
                icon="pi pi-list"
                onClick={() => setViewMode('list')}
                className="p-button-outlined"
                style={{
                  borderColor: viewMode === 'list' ? 'black' : '#C6C6C6',
                  color: viewMode === 'list' ? 'black' : '#C6C6C6',
                  backgroundColor: 'white',
                }}
              />
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <FilterSideBar />
            {viewMode === 'grid' ? (
              <div style={{ width: '100%' }}>
                <ProductCardList
                  products={products}
                  //stocks={stocks}
                  profilePictures={pictures}
                />
              </div>
            ) : (
              <ProductTableList
                products={products}
                filters={filters}
                //stocks={stocks}
                profilePictures={pictures}
              />
            )}{' '}
          </div>
        </div>
      )}
    </>
  );
}
