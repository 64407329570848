// ./context/AuthContext.tsx
import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from '../services/auth.service';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  token: string | null;
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [auth, setAuth] = useState<boolean>(AuthService.IsAuthenticated());
  const [token, setToken] = useState<string | null>(AuthService.GetAuthToken());
  const navigate = useNavigate();

  const handleLogin = async (username: string, password: string) => {
    const token = await AuthService.Login(username, password);
    if (token) {
      setAuth(true);
      setToken(token);
      navigate('/products');
    } else {
      setAuth(false);
      setToken(null);
    }
  };

  const handleLogout = () => {
    AuthService.LogOut();
    setAuth(false);
    setToken(null);
    navigate('/');
  };

  useEffect(() => {
    setAuth(AuthService.IsAuthenticated());
    setToken(AuthService.GetAuthToken());
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated: auth, login: handleLogin, logout: handleLogout, token }}>
      {children}
    </AuthContext.Provider>
  );
};
