import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { AxiosInterceptor } from './interceptors/interceptor';
import reportWebVitals from './reportWebVitals';
import './translations/i18n';

AxiosInterceptor();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <App/>,
);

reportWebVitals();
