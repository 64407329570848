import axios from 'axios';

export const ProductService = {
  GetProducts: async (businessUnit: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/product?businessUnit=${businessUnit}`
    
    );
    return response;
  },
  GetProductsByLanguage: async (idLanguage: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/product/language/${idLanguage}`
    );
    return response;
  },
};
