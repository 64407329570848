import axios from 'axios';
import { AuthService } from '../services/auth.service';

export const AxiosInterceptor = async () => {

  const addAuthToHeader = (request: any) => {
    const token = AuthService.GetAuthToken(); 
    if (token != null) {
      const authHeader = {
        Authorization: `Bearer ${token}`,
      };
      request.headers = {
        ...request.headers,
        ...authHeader,
      };
    }
    return request;
  };

  axios.interceptors.request.use((request) => {
    return addAuthToHeader(request);
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.error('Response Error:', error);
      return Promise.reject(error);
    },
  );
};
