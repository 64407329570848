import axios from 'axios';
import DelegateCustomer from '../models/DelegateCustomer';


export const DelegateService = {
  
  GetCustomersOfDelegate: async (email: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/client/delegates?email=${email}`,
    );
    return response;
  },
  SetDelegateCustomerInformation: (delegateCustomerChosen: DelegateCustomer) => {
    localStorage.setItem('DelegateCustomerID', delegateCustomerChosen.idDelegateClient); 
    localStorage.setItem('DelegateCompanyID', delegateCustomerChosen.idCompany); 
    localStorage.setItem('DelegateName', delegateCustomerChosen.nameDelegateClient); 
    localStorage.setItem('DelegateEmail', delegateCustomerChosen.emailDelegate); 
  },
  GetDelegateCustomerID: () => {
    return localStorage.getItem('DelegateCustomerID'); 
  }, 
  GetDelegateCompanyID: () => {
    return localStorage.getItem('DelegateCompanyID'); 
  }, 
  GetDelegateEmail: () => {
    return localStorage.getItem('DelegateEmail'); 
  }, 
  GetDelegateName: () => {
    return localStorage.getItem('DelegateName'); 
  }
  

};
