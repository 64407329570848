import { ReactNode, createContext, useContext, useState } from 'react';
import CartItem from '../models/CartItem';

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type ShoppingCartContext = {
  openCart: () => void;
  closeCart: () => void;
  getItemQuantity: (id: string) => number;
  increaseCartQuantity: (
    id: string,
    displayName: string,
    price: number,
    picture: string,
    lowest: number,
    multiple: number,
  ) => void;
  decreaseCartQuantity: (id: string) => void;
  setQuantity: (id: string, quantity: number) => void;
  removeFromCart: (id: string) => void;
  cartQuantity: number;
  emptyCart: () => void;
  cartItems: CartItem[];
};
const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [cartItems, setCartItems] = useState<CartItem[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  function getItemQuantity(id: string): number {
    return cartItems.find((item) => item.idProduct === id)?.quantity ?? 0;
  }
  const openCart = () => {
    setIsOpen(true);
  };
  const closeCart = () => {
    setIsOpen(false);
  };
  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0,
  );
  function setQuantity(id: string, newQuantity: number) {
    setCartItems((currItems) => {
      return currItems.map((item) => {
        if (item.idProduct === id) {
          let finalQuantity;
          if (item.lowest === 1 && item.multiple === 1) {
            finalQuantity = newQuantity;
          } else if (newQuantity < item.lowest) {
            finalQuantity = item.lowest;
          } else {
            finalQuantity =
              Math.ceil(newQuantity / (item.multiple ?? 1)) *
              (item.multiple ?? 1);
          }
          return {
            ...item,
            quantity: finalQuantity,
          };
        } else {
          return item;
        }
      });
    });
  }
  function increaseCartQuantity(
    id: string,
    displayName: string,
    price: number,
    picture: string,
    lowest: number,
    multiple: number,
  ) {
    setCartItems((currItems) => {
      if (currItems.find((item) => item.idProduct === id) == null) {
        return [
          ...currItems,
          {
            idProduct: id,
            displayName: displayName,
            picture: picture,
            price: price,
            quantity: lowest,
            lowest: lowest,
            multiple: multiple,
          },
        ];
      } else {
        return currItems.map((item) => {
          if (item.idProduct === id) {
            return { ...item, quantity: item.quantity + lowest };
          } else {
            return item;
          }
        });
      }
    });
  }
  function decreaseCartQuantity(idProduct: string) {
    setCartItems((currItems) => {
      const item = currItems.find((item) => item.idProduct === idProduct);
      if (item == null) {
        return currItems;
      }
      const newQuantity = item.quantity - item.multiple;
      if (newQuantity < item.lowest) {
        return currItems.filter((item) => item.idProduct !== idProduct);
      } else {
        return currItems.map((item) => {
          if (item.idProduct === idProduct) {
            return { ...item, quantity: newQuantity };
          } else {
            return item;
          }
        });
      }
    });
  }

  function removeFromCart(id: string) {
    setCartItems((currItems) => {
      return currItems.filter((item) => item.idProduct !== id);
    });
  }

  function emptyCart() {
    setCartItems([]);
  }

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        increaseCartQuantity,
        decreaseCartQuantity,
        setQuantity,
        removeFromCart,
        openCart,
        closeCart,
        cartQuantity,
        cartItems,
        emptyCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}
